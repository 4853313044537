import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { between } from 'polished'

import Layout from '../components/Layout'
import Section from '../components/Section'
import { AppConsumer } from '../contexts/AppContext'

const LargeText = styled.div`
  font-size: ${between('30px', '60px', '320px', '2560px')};
  font-weight: 700;

  a {
    &:hover {
      color: #009fe3;
    }
  }
`

const ContactOption = styled.div`
  margin-bottom: ${between('30px', '60px', '320px', '2560px')};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const ContactPage = React.memo(() => (
  <AppConsumer>
    {app => (
      <Layout>
        <Helmet>
          <title>Kontakt</title>
        </Helmet>

        <Section variant="blue">
          <h1>
            {app.isPersonalized
              ? app.isCompany
                ? null
                : `${app.purl.contact.salutation} ${app.purl.contact.lastName},`
              : null}
            {app.isPersonalized ? <br /> : null}
            {app.isPersonalized || app.isCompany ? 'w' : 'W'}ir sind für Sie da.
          </h1>
        </Section>

        <Section>
          <ContactOption>
            <strong>per Telefon</strong>
            <LargeText>07151 566 444</LargeText>
            <span>
              Mo-Fr 07.00-17.30 Uhr
              <br />
              Sa 08.00-12.00 Uhr
            </span>
          </ContactOption>

          <ContactOption>
            <strong>per E-Mail</strong>
            <LargeText>
              <a href="mailto:aboservice@zvw.de">aboservice@zvw.de</a>
            </LargeText>
          </ContactOption>
        </Section>
      </Layout>
    )}
  </AppConsumer>
))

export default ContactPage
